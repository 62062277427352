<template>
  <div class="content flex my-4">
    <div class="auth-form mx-auto my-auto">
      <div class="card">
        <div class="card-body text-center relative flex flex-column flex-justify-content-center flex-align-items-center">
          <h1 class="heading-bigger mb-2 width-narrow mx-auto">Confirm registration</h1>

          <div v-if="loaded">

            <f-alert
              v-if="authError"
              theme="danger"
              visible
              centered
              class="mt-2 mb-4">
              <template v-if="currentError">
                {{ currentError }}
              </template>
              <template v-else>
                Account activation error. Try again.
              </template>
            </f-alert>

            <template v-if="requestSent">
              <p class="heading-tiny mt-2 mb-5 width-narrow mx-auto">
                <template v-if="!authError">
                <span class="text-success">Account activation successful!</span><br>
                You can now use Your FunFit account.
                </template>
                <template v-else>
                  Something went wrong<br>
                  If you were not able to active Your account using activation link, please contact system Administrator.
                </template>
              </p>
            </template>
            <template v-else>
              <p class="heading-tiny my-4 width-narrow mx-auto">
                Click button below to activate Your account.
              </p>

              <f-button
                theme="secondary"
                big
                class="mb-4"
                @click.native="submitConfirm">
                Confirm registration
              </f-button>
            </template>

            <div class="text-center">
              <f-button
                theme="text"
                @click.native="$router.push('/login')">
                Go to login page
              </f-button>
            </div>
          </div>
          <div
            v-if="!loaded && !authError"
            class="loading-spinner-wrapper">
            <div class="loading-spinner pb-3 pt-3">
              <span class="loading-spinner-content">
                Loading data&hellip;
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'confirm-registration',
  data () {
    return {
      authError: false,
      currentError: null,
      loaded: false,
      requestSent: false
    }
  },
  mounted () {
    this.loaded = true;
  },
  methods: {
    submitConfirm () {
      let formDataToSend = { token: this.$route.params.token };
      Vue.set(this, 'loaded', false);

      this.$http.post('confirm-user/check', formDataToSend).then((response) => {
        Vue.set(this, 'loaded', true);
        Vue.set(this, 'requestSent', true);

        if (('success' in response.data) && response.data.success === 0) {
          this.authError = true;
          if (response.data.message) {
            this.currentError = response.data.message;
          }
        }
      }).catch((error) => {
        Vue.set(this, 'loaded', true);
        Vue.set(this, 'requestSent', true);
        console.log(error);
        this.authError = true;
        this.currentError = error.response.data.message;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-body {
  min-height: 350px;

  .loading-spinner {
    top: 60%;
  }
}
</style>
